import imageMapResize from 'image-map-resizer';

const data = {
  KoochichingCounty: {
    image: 'KoochichingCounty.png',
    caption:
      '<p>Laurie Chellico</p><p>Invest Early</p><p><a href="mailto:lauriec@kootasca.org">lauriec@kootasca.org</a><br><a href="tel:218-999-0815">218-999-0815</a></p>',
    arrow: 'arrow-left',
    top: '240px',
    left: '27.64306499%',
  },
  ItascaCounty: {
    image: 'ItascaCounty.png',
    caption:
      '<p>Laurie Chellico</p><p>Invest Early</p><p><a href="mailto:lauriec@kootasca.org">lauriec@kootasca.org</a><br><a href="tel:218-999-0815">218-999-0815</a></p>',
    arrow: 'arrow-left',
    top: '381px',
    left: '23.47235694%',
  },
  AitkinCounty: {
    image: 'AitkinCounty.png',
    caption:
      '<p>Cheryl Meld</p><p>McGregor School District</p><p><a href="mailto:cmeld@isd4.org">cmeld@isd4.org</a><br><a href="tel:218-768-5139">218-768-5139</a></p>',
    arrow: 'arrow-left',
    top: '612px',
    left: '27.15809893%',
  },
  NorthernStLouisCounty: {
    image: 'NorthernStLouisCounty.png',
    caption:
      '<p>Planning Department</p><p>AEOA</p><p><a href="mailto:planning@aeoa.org">planning@aeoa.org</a><br><a href="tel:218-749-2912">218-749-2912</a></p>',
    arrow: 'arrow-bottom',
    top: '228px',
    left: '24.15130941%',
  },
  FondDuLac: {
    image: 'FondDuLac.png',
    caption:
      '<p>Jane Deverney</p><p>Fond du Lac Human Services</p><p><a href="mailto:janedeverney@fdlrez.com">janedeverney@fdlrez.com</a><br><a href="tel:218-269-7657">218-269-7657</a></p>',
    arrow: 'arrow-bottom',
    top: '480px',
    left: '20.3685742%',
  },
  CarltonCounty: {
    image: 'CarltonCounty.png',
    caption:
      '<p>Alexandria &ldquo;Alex&rdquo; Adolfs</p><p>Carlton County</p><p><a href="mailto:alexandria.adolfs@co.carlton.mn.us">alexandria.adolfs<br>@co.carlton.mn.us</a><br><a href="tel:218-900-7345">218-900-7345</a></p>',
    arrow: 'arrow-bottom',
    top: '524px',
    left: '20.3685742%',
  },
  LakeCounty: {
    image: 'LakeCounty.png',
    caption:
      '<p>Planning Department</p><p>AEOA</p><p><a href="mailto:planning@aeoa.org">planning@aeoa.org</a><br><a href="tel:218-749-2912">218-749-2912</a></p>',
    arrow: 'arrow-right',
    top: '355px',
    left: '22.50242483%',
  },
  Duluth: {
    image: 'Duluth.png',
    caption:
      '<p>Diane Mozol</p><p>Duluth Public Schools</p><p><a href="mailto:diane.mozol@isd709.org">diane.mozol@isd709.org</a><br><a href="tel:218-461-7250">218-461-7250</a></p>',
    arrow: 'arrow-bottom',
    top: '458px',
    left: '29.00096993%',
  },
  CookCounty: {
    image: 'CookCounty.png',
    caption:
      '<p>Jodi Tervo Roberts</p><p>Sawtooth Mountain Clinic</p><p><a href="mailto:communityhub@sawtoothmountainclinic.org">communityhub @sawtoothmountainclinic.org</a><br><a href="tel:218-387-2330;818">218-387-2330 ext. 818</a></p>',
    arrow: 'arrow-right',
    top: '302px',
    left: '34.91755577%',
  },
};

export function navigatorMap() {
  console.log('init');
  const map = document.querySelector('#navigator-map');
  const image = document.querySelector('#navigator-map-image') as HTMLImageElement;
  const info = document.querySelector('#navigator-map-info') as HTMLElement;

  if (!map) return;
  if (!info) return;

  const areas = map.querySelectorAll('[data-location]');

  // preload images
  Object.keys(data).forEach((key) => {
    const item = data[key as keyof typeof data];
    new Image().src = `/wp-content/themes/northlandfdn-theme/app/assets/navigator/${item.image}`;
  });

  const handleMouseenter = (e: any) => {
    const location = e.target.getAttribute('data-location');

    const item = data[location as keyof typeof data];

    if (!window.matchMedia('(max-width: 768px)').matches) {
      image.setAttribute('src', `/wp-content/themes/northlandfdn-theme/app/assets/navigator/${item.image}`);
      info.className = `box ${item.arrow}`;
      info.style.top = `calc(${item.top} - 200px)`;
      info.style.left = item.left;
      info.innerHTML = item.caption;
    }
  };

  const handleClick = (e: any) => {
    const location = e.target.getAttribute('data-location');

    const item = data[location as keyof typeof data];

    if (window.matchMedia('(max-width: 768px)').matches) {
      const w = window.innerWidth;
      const l = (w - 340) / 2;
      image.setAttribute('src', `/wp-content/themes/northlandfdn-theme/app/assets/navigator/${item.image}`);
      info.className = `box ${item.arrow}`;
      info.style.setProperty('--m-left', l + 'px');
      info.style.top = '';
      info.style.left = '';
      info.innerHTML = item.caption;
    }
  };

  areas.forEach((area) => {
    area.addEventListener('mouseenter', handleMouseenter);
    area.addEventListener('click', handleClick);
  });

  document.addEventListener('DOMContentLoaded', function () {
    imageMapResize();
  });

  window.addEventListener('resize', (e) => {
    if (window.matchMedia('(max-width: 768px)').matches) {
      const w = window.innerWidth;
      const l = (w - 340) / 2;

      info.style.setProperty('--m-left', l + 'px');
    }
  });
}
